<template>
  <Layout ref="layout">
    <div v-if="isSuspend != true" class="head-enterprise-page pl-4 mb-3">
      <div class="row">
        <div class="col-md-6">
          <div class="box-plan-storage mb-3">
            <div class="d-flex justify-content-between">
              <span class="label-settings-name">Enterprise</span>
              <span class="amount-text-setting-v3">Active</span>
            </div>
            <div>
              <hr class="horizontal-plan-enterprise">
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div>
                <span class="plan-settings-text mr-2">Subscription fee</span>
                <img src="@/assets/images/icon/info-grey.svg"
                  class="cursor-pointer"
                  data-toggle="tooltip"
                  title="Price of the subscription, which excludes a seat fee."
                  alt="">
              </div>
              <span class="plan-settings-text">{{enterprisePlan.subscription_fee}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div>
                <span class="plan-settings-text mr-2">Additional member fee</span>
                <img src="@/assets/images/icon/info-grey.svg"
                  class="cursor-pointer"
                  data-toggle="tooltip"
                  title="A fee which is charged when the admin adds more seats to this enterprise."
                  alt="">
              </div>
              <span class="plan-settings-text">{{enterprisePlan.aditional_fee}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span class="plan-settings-text">Next billing date</span>
              </div>
              <span class="plan-settings-text">{{enterprisePlan.next_billing}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <div class="box-plan-storage mb-3">
                <p class="label-settings-name">Payment Method</p>
                <div class="box-payment-v3">
                    <img src="@/assets/images/icon/visa-logo.svg" alt="">
                    <span class="label-settings-name mx-3">•••• •••• •••• {{enterprisePlan.card ? enterprisePlan.card.last4 : ''}}</span>
                    <span class="cc-date">{{enterprisePlan.card ? enterprisePlan.card.exp_month : ''}}/{{enterprisePlan.card ? getLast2Num(enterprisePlan.card.exp_year) : ''}}</span>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn-payment-method-v3">Manage</button>
                </div>
            </div>
        </div>
      </div>
      <span class="label-settings-name">Billing and invoicing</span>
    </div>
    <div v-if="isSuspend != true" class="list-enterprise-member px-4">
      <table class="table-enterprise">
        <thead>
          <th class="history-payment-head">Date</th>
          <th class="history-payment-head">Plan</th>
          <th class="history-payment-head">Status</th>
          <th class="history-payment-head">Amount</th>
          <th class="history-payment-head">Invoice</th>
        </thead>
        <tbody v-if="enterprisePlan.historys.length > 0">
          <tr v-for="(payment, idx) in enterprisePlan.historys" :key="idx">
            <td class="history-payment-head">
              <div class="d-flex align-items-center">
                <span class="enterprise-member-list-text">
                  {{payment.date}}
                </span>
              </div>
            </td>
            <td class="history-payment-head">
              <span class="enterprise-member-list-text">
                {{payment.plan}}
              </span>
            </td>
            <td class="history-payment-head">
              <span
                :class="payment.status.toLowerCase() == 'paid' ? 'green-text-status'
                : payment.status.toLowerCase() == 'rejected' ? 'red-text-status'
                : 'black-text-status'"
              >
                {{capitalizeFirstLetter(payment.status)}}
              </span>
            </td>
            <td class="history-payment-head">
              <span class="enterprise-member-list-text">
                {{payment.amount}}
              </span>
            </td>
            <td class="history-payment-head">
              <a :href="payment.link_invoice" class="enterprise-member-list-text-invoice">
                Download
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div v-if="members.length <= 0" class="d-flex justify-content-center align-items-center flex-column">
        <img src="@/assets/images/empty-member-enterprise.svg" class="mb-1" style="height: 128px; width: 128 px;" alt="">
        <span class="mb-1 empty-word-title">No enterprise member</span>
      </div> -->
    </div>
    <div v-if="isSuspend != true" class="footer-payment-histories">
        <span class="">NOTE: We only show up 1 year of payment history.</span>
    </div>
    <SuspendPage v-if="isSuspend == true"/>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import SuspendPage from './suspendEnterprise.vue'
export default {
    data() {
      return {
        members: [],
        paymentHistory: [
          {
            date: 'Mar 26, 2023',
            plan: 'Additional member',
            status: 'paid',
            amount: '$10.29/month',
            invoice: '-',
            id: 1
          },
          {
            date: 'Feb 26, 2023',
            plan: 'Additional member',
            status: 'paid',
            amount: '$10.29/month',
            invoice: '-',
            id: 2
          },
          {
            date: 'Jan 26, 2023',
            plan: 'Additional member',
            status: 'paid',
            amount: '$10.29/month',
            invoice: '-',
            id: 3
          },
          {
            date: 'Des 26, 2022',
            plan: 'Additional member',
            status: 'paid',
            amount: '$10.29/month',
            invoice: '-',
            id: 4
          },
          {
            date: 'Des 26, 2022',
            plan: 'Enterprise',
            status: 'paid',
            amount: '$10.29/month',
            invoice: '-',
            id: 5
          }
        ]
      }
    },
    components: {
        Layout,
        SuspendPage
    },
    mounted(){
      this.$store.dispatch('enterprise/getMyEnterprise')
      this.$store.dispatch('enterprise/getPlanEnterprise')
      if(this.$store.getters['state/state'] != 'enterprise'){
        this.$router.push({name:'home'}).catch({})
      }
    },
    computed: {
      isSuspend(){
        return this.$store.getters['enterprise/statusSuspend']
      },
      enterprisePlan(){
        return this.$store.getters['enterprise/planEnterprise']
      }
    },
    methods: {
        capitalizeFirstLetter(str){
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        getLast2Num(value){
            var strValue = value.toString()
            var twoNum = strValue.slice(-2)
            return parseInt(twoNum)
        }
    }
}
</script>

<style>

</style>