<template>
    <div class="d-flex justify-content-center flex-column align-items-center suspends">
        <img src="@/assets/images/suspend-img.svg" style="height:128px; width: 128px;" class="my-3" alt="">
        <span class="mb-2">Your enterprise account is locked</span>
        <span class="mb-3"><span>Please update your payment method or renew your plan so you can organize, share, and secure your files again.</span></span>
        <button @click="updatePayment()">Update Payment</button>
    </div>
</template>

<script>
export default {
    computed: {
    },
    methods: {
        updatePayment(){
            // window.open(link,"_self")
        }
    }
}
</script>

<style>

</style>